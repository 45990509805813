import React from 'react';
import dataDesktop from './dataDesktop';
import Legal from './Legal';
import SocialNetworks from './SocialNetworks';
import { footerDesktop as Styled } from './styles';

const FooterDesktop = () => (
	<Styled.Container>
		<Styled.Content>
			<Styled.Left>
				{dataDesktop.info.map(item => (
					<div key={item.title}>
						<Styled.Title>{item.title}</Styled.Title>
						<Styled.Text href={item.href}>{item.text}</Styled.Text>
						{item.href2 && (
							<>
								<br />
								<Styled.Text href={item.href2}>{item.text2}</Styled.Text>
							</>
						)}
					</div>
				))}
			</Styled.Left>
			<Styled.Right>
				<Styled.LinksContainer>
					{dataDesktop.links.map(link => (
						<Styled.Link key={link.text} to={link.href}>
							{link.text}
						</Styled.Link>
					))}
				</Styled.LinksContainer>
				<SocialNetworks />
			</Styled.Right>
		</Styled.Content>
		<Legal />
	</Styled.Container>
);

export default FooterDesktop;
