import routes from 'routes';

export default [
	{
		text: 'Inicio',
		href: routes.home,
		icon: 'home',
		notVisibleOnDesktop: true
	},
	{
		text: 'Sobre nosotros',
		href: routes.aboutUs,
		icon: 'info',
		iconSize: 18
	},
	{
		text: 'Repuestos',
		href: routes.categories,
		icon: 'categories'
	},
	{
		text: 'Preguntas frecuentes',
		href: routes.faq,
		icon: 'quest',
		iconSize: 19,
		notVisibleOnDesktop: true
	},
	{
		text: 'Contacto',
		href: routes.contact,
		icon: 'contact',
		iconSize: 19
	}
];
