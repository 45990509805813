import styled from 'styled-components';
import { colors } from 'theme';

export const StyledError = styled.div`
	color: ${colors.red};
	font-size: 10px;
	margin-top: 2px;
	text-align-last: left;
`;

export const StyledFieldContainer = styled.div`
	width: 100%;
`;
