import styled from 'styled-components';
import { colors, media, mixins } from 'theme';

export default styled.section`
	padding-top: 4px;
	${media.tablet`
		display: flex;
		justify-content: space-between;
		${mixins.center};
		padding-top: 0;
	`}
`;

export const feature = {
	Container: styled.div`
		background-color: #fff;
		text-align: center;
		padding: 26px 25px 23px;
		position: relative;

		${media.tablet`
			max-width: 376px;
			padding-top: 20px;
		`};

		&:after {
			content: '';
			position: absolute;
			width: 171px;
			height: 1px;
			background-color: ${colors.grey3};
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}

		&:last-child:after {
			display: none;
		}

		${media.tablet`
			&:after {
				display: none;
			}
		`}
	`,
	IconContainer: styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		fill: ${colors.lightBlue};

		${media.tablet`
			height: 74px;
		`}
	`,
	Title: styled.h3`
		font-family: 'Roboto', sans-serif;
		font-size: 17px;
		font-weight: 500;
		margin-top: 8px;
		${media.tablet`
			margin-top: 19px;
			font-size: 18px;
		`}
	`,
	Description: styled.div`
		margin-top: 10px;
		font-size: 14px;
		color: ${colors.grey};
		margin-bottom: 16px;
		line-height: 1.1;

		${media.tablet`
			font-size: 15px;
			margin-top: 8px;
		`}
	`
};
