import { sendContactMessage } from 'api';
import history from 'utils/history';
import * as types from './types';

export const addProduct = product => ({
	type: types.ADD_PRODUCT,
	product
});

export const removeProduct = id => ({
	type: types.REMOVE_PRODUCT,
	id
});

const sendMessageRequest = () => ({
	type: types.SEND_MESSAGE_REQUEST
});
const sendMessageSuccess = () => ({
	type: types.SEND_MESSAGE_SUCCESS
});

export const sendMessage = (data, resetForm) => async (dispatch, getState) => {
	dispatch(sendMessageRequest());

	const { productRequest } = getState();
	await sendContactMessage({ ...data, products: productRequest.products });
	resetForm();
	dispatch(sendMessageSuccess());
};

export const closeSuccessModalAction = () => ({
	type: types.CLOSE_SUCCESS_MODAL
});

export const closeSuccessModal = () => dispatch => {
	dispatch(closeSuccessModalAction());
	history.push('/');
};
