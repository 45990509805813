import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { actions } from 'modules/home';
import SuccessModal from 'components/SuccessModal';

const OwnSucessModal = ({ isOpen, closeModal }) => (
	<SuccessModal isOpen={isOpen} closeModal={closeModal}>
		<Button onClick={closeModal} variant="outlined" color="primary">
			CONTINUAR NAVEGANDO
		</Button>
	</SuccessModal>
);

OwnSucessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isOpen: state.home.contactModalIsOpen
});

const mapDispatchToProps = {
	closeModal: actions.closeContactModal
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OwnSucessModal);
