import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from 'components/Icon';
import { actions } from 'modules/productRequest';
import EmptyImage from 'components/EmptyImage';
import { product as styled } from './styles';

const Product = ({ id, name, image, removeProduct }) => (
	<styled.Container>
		<styled.CloseButton onClick={removeProduct.bind(null, id)}>
			<Icon name="close" size={10} />
		</styled.CloseButton>
		{image ? 'image' : <EmptyImage size="small" />}
		<styled.Text>{name}</styled.Text>
	</styled.Container>
);

Product.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	image: PropTypes.string,
	removeProduct: PropTypes.func.isRequired
};

const mapDispatchToProps = {
	removeProduct: actions.removeProduct
};

export default connect(
	null,
	mapDispatchToProps
)(Product);
