import styled from 'styled-components';
import { colors } from 'theme';

export default styled.input`
	border: 1px solid ${props => (props.error ? colors.red : colors.grey3)};
	height: 40px;
	border-radius: 2px;
	padding-left: 18px;
	font-size: 15px;
`;
