import styled, { css } from 'styled-components';
import sobreDefaBackground from 'images/bg-sobre-defa.jpg';
import sobreDefaBackgroundDesktop from 'images/bg-sobre-defa-desktop.png';
// import sobreDefaBackgroundX2 from 'images/bg-sobre-defa-x2.png';
import argentina from 'images/argentina.svg';
import Button from '@material-ui/core/Button';
import { colors, media, mixins } from 'theme';

export default {
	AboutDefaAndContactContainer: styled.div`
		${media.tablet`
			display: flex;
			background: url(${sobreDefaBackgroundDesktop}) top left no-repeat;
			margin-top: 56px;
			padding-bottom: 131px;
			${mixins.center}
		`}
	`
};

const Slide = styled.div`
	width: 100%;
	padding-top: 36px;
	padding-left: 38px;
	padding-right: 38px;
	color: #fff;
	background: url(${props => props.image}) center no-repeat;
	background-size: cover;
	overflow: hidden;
	text-align: center;

	${props =>
		props.imageX2 &&
		css`
			@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
				background: url(${props.imageX2}) top center no-repeat;
				background-size: 360px auto;
			}
		`}

	${props =>
		props.imageX4 &&
		css`
			@media (-webkit-min-device-pixel-ratio: 3.25), (min-resolution: 192dpi) {
				background: url(${props.imageX4}) top center no-repeat;
				background-size: 360px auto;
			}
		`}

	&.swiper-slide {
		height: 250px;
	}
`;

export const bannerSlider = {
	Img: styled.img`
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	`,
	Slide,
	SlideWithoutText: styled(Slide)`
		padding-top: 135px;
		padding-right: 25px;
		display: flex;
		justify-content: flex-end;
	`,
	Title: styled.div`
		font-size: 26px;
		font-weight: 700;
	`,
	Text: styled.div`
		font-size: 15px;
		margin-top: 10px;
	`,
	Button: styled(Button).attrs({
		variant: 'contained',
		color: 'primary'
	})`
		width: 168px;
		margin-left: auto;
	`
};

export const aboutDefa = {
	Container: styled.section`
		background: url(${sobreDefaBackground}) top right no-repeat;
		background-size: 360px auto;
		background-color: ${colors.backgroundGrey};
		padding: 45px 35px 42px;
		text-align: center;

		${media.tablet`
			text-align: left;
			padding: 55px 0 0;
			max-width: 566px;
			margin-right: auto;
			background: none;
		`}

		${media.desktopB`
			max-width: 650px;
		`}
	`,
	Title: styled.h2`
		font-size: 20px;
		font-weight: 700;

		${media.tablet`
			font-size: 22px;
		`}
	`,
	Text: styled.div`
		font-size: 14px;
		color: ${colors.grey};
		margin-top: 12px;
		margin-bottom: 19px;
		line-height: 1.2;

		${media.tablet`
			font-size: 15px;
			margin-top: 24px;
		`}
	`
};

export const shipping = {
	Container: styled.div`
		height: 90px;
		border-bottom: 1px solid #d5d5d5;
		background: url(${argentina}) no-repeat right;
		display: flex;
		align-items: center;
	`,
	IconWrapper: styled.div`
		width: 47px;
		height: 47px;
		border: 1px solid #d5d5d5;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		fill: ${colors.lightBlue};
	`,
	TextWrapper: styled.div`
		margin-left: 23px;
		font-family: 'Roboto', sans-serif;
	`,
	Text: styled.div`
		font-size: 15px;
		font-weight: 500;
	`,
	Link: styled.a`
		color: ${colors.lightBlue};
		font-size: 14px;
		margin-top: 3px;
	`
};

export const bannerSliderDesktop = {
	Container: styled.div`
		line-height: 0;
		overflow: hidden;
	`,
	Img: styled.img`
		position: relative;
		left: 50%;
		transform: translate(-50%);
	`
};

export const newNumberAlert = {
	Container: styled.div`
		background-color: #000;
		color: #fff;
	`,
	Center: styled.div`
		position: relative;
		height: 42px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		${mixins.center}
	`,
	Text: styled.p`
		font-weight: 700;
		font-size: 16px;
		padding-left: 30px;
		letter-spacing: 1px;

		${media.mobile`
			padding-left: 10px;
		`}
	`,
	CloseButton: styled.button`
		position: absolute;
		width: 32px;
		height: 32px;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto 0;
		fill: #fff;
		padding: 10px;
		line-height: 0;
	`
};
