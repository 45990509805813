import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styled from './styles';

const ICON_PIXELS_BY_SIZE = {
	small: 25,
	normal: 37
};

const EmptyImage = ({ size }) => (
	<styled.Container size={size}>
		<Icon size={ICON_PIXELS_BY_SIZE[size]} name="photo" />
		<styled.Text size={size}>IMAGEN NO DISPONIBLE</styled.Text>
	</styled.Container>
);

EmptyImage.propTypes = {
	size: PropTypes.oneOf(['small', 'normal'])
};

EmptyImage.defaultProps = {
	size: 'normal'
};

export default EmptyImage;
