import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { actions } from 'modules/page';
import { item as styled } from './styles';

const Item = ({ closeMenu, href, icon, iconSize, text }) => (
	<styled.Container to={href} onClick={closeMenu}>
		<styled.IconContainer>
			<Icon size={iconSize} name={icon} />
		</styled.IconContainer>
		<span>{text}</span>
	</styled.Container>
);

Item.propTypes = {
	closeMenu: PropTypes.func.isRequired,
	href: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number,
	text: PropTypes.string.isRequired
};

Item.defaultProps = {
	iconSize: 20
};

const mapDispatchToProps = {
	closeMenu: actions.closeMenu
};

export default connect(
	null,
	mapDispatchToProps
)(Item);
