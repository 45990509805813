export default [
	{
		icon: 'delivery',
		iconSize: 62,
		iconSizeDesktop: 74,
		title: 'Envíos a todo el país',
		description:
			'Efectuamos envíos en el día a través de micros y camiones por medio de empresas de transporte a elección del cliente.',
		link: '/',
		id: 'envios'
	},
	{
		icon: 'creditcard',
		iconSize: 48,
		iconSizeDesktop: 58,
		title: 'Distintos medios de pago',
		description:
			'Efectivo, Mercado Pago, Tarjeta de crédito y debito Visa o Cabal de todos los bancos o Transferencia bancaria. Consulte plan de cuotas con intereses.',
		link: '/'
	},
	{
		icon: 'boxes',
		iconSize: 51,
		iconSizeDesktop: 62,
		title: 'El mayor stock al mejor precio',
		description:
			'Contamos con miles de repuestos con una amplia variedad de marcas a disposición y al mejor precio del mercado.',
		link: '/'
	}
];
