import styled from 'styled-components';
import { media, mixins } from 'theme';
import { NavLink } from 'react-router-dom';

export default {
	Container: styled.footer`
		background-color: #000;
	`,
	Content: styled.div`
		padding: 31px 36px 40px;
		color: #fff;
		font-size: 14px;
		border-bottom: 1px solid #d5d5d5;
	`,
	Groups: styled.div`
		display: grid;
		row-gap: 20px;
		margin-top: 36px;
	`,
	Group: styled.div`
		font-size: 14px;
	`,
	GroupTitle: styled.div`
		margin-bottom: 7px;
	`,
	GroupItem: styled.div`
		margin-bottom: 8px;
		color: #c7c5c5;
		display: ${props => (props.whatsapp ? 'flex' : 'block')};

		.whatsapp-text {
			margin-right: 2px;
		}
		.whatsapp-link {
			margin-bottom: 4px;
		}
	`
};

export const legal = {
	Container: styled.div`
		background-color: #111;
		border-top: 1px solid #d5d5d5;
	`,
	Content: styled.div`
		height: 40px;
		color: #c7c5c5;
		font-size: 10px;
		font-weight: 300;
		display: flex;
		align-items: center;
		justify-content: center;

		${media.tablet`
			${mixins.center};
			justify-content: flex-start;
		`}
	`
};

export const footerDesktop = {
	Container: styled.footer`
		background-color: #000;
	`,
	Content: styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 48px;
		padding-bottom: 48px;
		${mixins.center};
	`,
	Left: styled.div`
		display: flex;
		justify-content: space-between;
		width: 64%;
		min-width: 664px;
	`,
	Title: styled.div`
		color: #fff;
		font-size: 15px;
	`,
	Text: styled.a`
		margin-top: 6px;
		font-size: 15px;
		color: #c7c5c5;
	`,
	Right: styled.div`
		display: flex;
		justify-content: space-between;
		width: 26.5%;
	`,
	LinksContainer: styled.div`
		display: grid;
		row-gap: 16px;
	`,
	Link: styled(NavLink)`
		font-size: 14px;
		color: #fff;
	`
};
