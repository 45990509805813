export default {
	phone: '4942-9688',
	phoneHref: 'tel:49429688',
	celphone: '11 6303 5350',
	celphoneHref: 'tel:1163035350',
	whatsapp: '+54 911 6303-5350',
	whatsappHref: 'https://wa.me/5491163035350',
	contactMail: 'consultas@defarepuestos.com.ar',
	address: 'Rondeau 2529, CABA',
	addressHref: 'https://goo.gl/maps/qv6dJaPpYVB2',
	facebook: 'https://www.facebook.com/defarepuestos',
	instagram: 'https://instagram.com'
};
