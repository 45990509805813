import React from 'react';
import { NavLink } from 'react-router-dom';
import banner from 'images/banners/repuestos-desktop.png';
import { bannerSliderDesktop as Styled } from './styles';

const BannerSliderDesktop = () => (
	<Styled.Container>
		<NavLink to="/?section=consulta">
			<Styled.Img
				src={banner}
				alt="Repuestos para motores y tractores DEUTZ"
				width="1920"
				height="350"
			/>
		</NavLink>
	</Styled.Container>
);

export default BannerSliderDesktop;
