import styled from 'styled-components';
import { colors } from 'theme';

export default {
	Container: styled.div`
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		padding-top: 51px;
		padding-bottom: 36px;
	`,
	Top: styled.div`
		padding-left: 18px;
	`,
	BackButton: styled.button`
		font-family: 'Roboto', sans-serif;
		display: flex;
		font-size: 16px;
		color: ${colors.red};
		fill: ${colors.red};
		align-items: center;
	`,
	BackButtonText: styled.span`
		margin-left: 16px;
	`,
	Title: styled.div`
		font-family: 'Roboto', sans-serif;
		margin-top: 4px;
		font-size: 23px;
		font-weight: 700;
	`
};

export const StyledOptions = styled.div`
	margin-top: 38px;
	margin-bottom: auto;
`;

export const option = {
	Container: styled.a`
		font-size: 15px;
		border-bottom: 1px solid #f1f1f1;
		padding-top: 21px;
		padding-bottom: 21px;
		padding-left: 18px;
		padding-right: 30px;
		display: flex;
		align-items: center;

		&:first-child {
			border-top: 1px solid #f1f1f1;
		}
	`,
	IconContainer: styled.div`
		width: 66px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
	`,
	Text: styled.div`
		padding-left: 15px;
	`,
	SmallText: styled.div`
		color: #9d9d9d;
	`
};
