import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import routes from 'routes';
import { category as styled } from './styles';

const Category = ({ name }) => (
	<styled.Container
		to={routes.products.replace(':category', name.toLowerCase().replace(/ /g, '-'))}
	>
		{name}
		<styled.See>
			<styled.SeeText>VER</styled.SeeText>
			<Icon name="right" size={13} />
		</styled.See>
	</styled.Container>
);

Category.propTypes = {
	name: PropTypes.string.isRequired
};

export default Category;
