import * as types from './types';

const initialState = {
	callModalIsOpen: false,
	menuIsOpen: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.OPEN_CALL_MODAL:
			return {
				...state,
				callModalIsOpen: true
			};
		case types.CLOSE_CALL_MODAL:
			return {
				...state,
				callModalIsOpen: false
			};
		case types.OPEN_MENU:
			return {
				...state,
				menuIsOpen: true
			};
		case types.CLOSE_MENU:
			return {
				...state,
				menuIsOpen: false
			};
		case types.TOGGLE_MENU:
			return {
				...state,
				menuIsOpen: !state.menuIsOpen
			};
		default:
			return state;
	}
}
