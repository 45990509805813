import React from 'react';
import data from 'data';
import Icon from 'components/Icon';
import whastappLogo from 'images/whatsapp.svg';
import Option from './Option';
import { StyledOptions } from './styles';

const Options = () => (
	<StyledOptions>
		<Option
			text="Llamada telefónica"
			smallText={data.celphone}
			href={data.celphoneHref}
			renderIcon={() => <Icon name="phone-call" size={26} />}
		/>
		<Option
			text="Enviar un mensaje por WhatsApp"
			href={data.whatsappHref}
			renderIcon={() => <img width="32" src={whastappLogo} alt="Whatsapp" />}
		/>
		<Option
			text="Enviar un e-mail"
			href={`mailto: ${data.contactMail}`}
			renderIcon={() => <Icon name="email2" color="#178BFF" size={30} />}
		/>
		<Option
			text="Enviar un mensaje por Facebook"
			href={data.facebook}
			renderIcon={() => <Icon name="facebook" color="#475993" size={31} />}
		/>
	</StyledOptions>
);

export default Options;
