import React from 'react';
import Input from 'components/Input';
import { StyledError, StyledFieldContainer } from './styles';

// eslint-disable-next-line react/prop-types
const FieldInput = ({ field, form, innerRef, ...props }) => (
	<StyledFieldContainer>
		<Input
			ref={innerRef}
			error={form.submitCount && !!form.errors[field.name]}
			{...field}
			{...props}
		/>
		{form.submitCount && form.errors[field.name] ? (
			<StyledError>{form.errors[field.name]}</StyledError>
		) : null}
	</StyledFieldContainer>
);

export default FieldInput;
