import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { socialNetwork as Styled } from './styles';

const SocialNetwork = ({ icon, iconSize, href }) => (
	<Styled.Container href={href} target="_blank">
		<Icon size={iconSize} name={icon} />
	</Styled.Container>
);

SocialNetwork.propTypes = {
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number.isRequired,
	href: PropTypes.string.isRequired
};

export default SocialNetwork;
