import styled from 'styled-components';
import { colors, media } from 'theme';

export default {
	Container: styled.section`
		background-color: ${colors.backgroundGrey};
		padding-top: 58px;
		padding-bottom: 100px;
		text-align: center;

		.swiper-wrapper {
			justify-content: center;
		}
	`,
	Title: styled.h2`
		font-size: 22px;
		font-weight: 700;
	`,
	Text: styled.div`
		color: ${colors.grey};
		font-size: 14px;
		max-width: 292px;
		margin: 26px auto 46px;
		${media.tablet`
			max-width: none;
		`};
	`,
	Slide: styled.div`
		background-color: #ffffff;
		border-radius: 3px;
		width: 131px;
		height: 102px;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	Img: styled.img`
		max-width: 100px;
	`
};
