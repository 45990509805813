import styled from 'styled-components';
import { colors, media } from 'theme';

export default {
	Container: styled.div`
		padding-top: 30px;
		padding-bottom: 100px;
	`,
	Top: styled.div`
		padding-left: 9px;
		padding-right: 9px;
	`,
	Title: styled.h1`
		font-family: 'Roboto', sans-serif;
		font-size: 23px;
		font-weight: 700;
	`,
	Bottom: styled.div`
		${media.tablet`
			display: flex;
		`}
	`,
	Schedule: styled.div`
		margin-top: 7px;
		font-size: 14px;
		color: ${colors.grey};
	`,
	MapContainer: styled.div`
		width: 100%;
		height: 257px;
		margin-top: 24px;
	`
};

export const StyledOptions = styled.div`
	fill: ${colors.red};
	margin-top: 20px;
	${media.tablet`
		width: 350px;
		flex-shrink: 0;
	`}
`;

export const option = {
	Container: styled.a`
		height: 62px;
		display: flex;
		align-items: center;
	`,
	IconContainer: styled.div`
		display: flex;
		width: 30px;
		justify-content: center;
		align-items: center;
	`,
	TextContainer: styled.div`
		margin-left: 30px;
		font-weight: 600;
		font-size: 15px;
	`,
	Text: styled.div`
		color: ${colors.grey};
		font-weight: 400;
		margin-top: 2px;
	`
};
