import React from 'react';
import PropTypes from 'prop-types';
import { MediaMobile } from 'components/Media';
import Header, { HeaderDesktop } from './Header';
import Footer, { FooterDesktop } from './Footer';
import WhatsappButton from './WhatsappButton';
// import SearchBar from './SearchBar';
import Menu from './Menu';
import StyledPage from './styles';

const Page = ({ children }) => (
	<StyledPage>
		<MediaMobile>{matches => (matches ? <Header /> : <HeaderDesktop />)}</MediaMobile>
		<Menu />
		{/* <SearchBar /> */}
		{children}
		<WhatsappButton />
		<MediaMobile>{matches => (matches ? <Footer /> : <FooterDesktop />)}</MediaMobile>
	</StyledPage>
);

Page.propTypes = {
	children: PropTypes.node
};

export default Page;
