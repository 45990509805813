import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { option as styled } from './styles';

const Option = ({ icon, iconSize, title, text, href }) => (
	<styled.Container href={href}>
		<styled.IconContainer>
			<Icon name={icon} size={iconSize} />
		</styled.IconContainer>
		<styled.TextContainer>
			<div>{title}</div>
			<styled.Text>{text}</styled.Text>
		</styled.TextContainer>
	</styled.Container>
);

Option.propTypes = {
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired
};

export default Option;
