import styled from 'styled-components';
import Button from 'components/Button';
import { media } from 'theme';

export default {
	Container: styled.form`
		padding: 56px 29px 101px;
		text-align: center;

		input,
		textarea {
			width: 100%;
		}

		${media.tablet`
			background-color: #fff;
			flex-shrink: 0;
			width: 376px;
			padding: 55px 26px 61px;
		`}
	`,
	Title: styled.h2`
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 45px;
	`,
	InputsContainer: styled.div`
		display: grid;
		row-gap: 30px;
		margin-bottom: 36px;
	`,
	Button: styled(Button).attrs({
		variant: 'contained',
		color: 'primary',
		type: 'submit'
	})`
		width: 145px;
		height: 40px;
	`,
	ButtonText: styled.span`
		margin-left: 9px;
	`
};
