import styled, { css } from 'styled-components';
import { colors, timingFunctions, mixins } from 'theme';
import { NavLink } from 'react-router-dom';

const LogoContainer = styled(NavLink)`
	display: flex;
	height: 30px;

	img {
		height: 100%;
	}
`;

export default {
	Container: styled.header`
		height: 50px;
		${mixins.center};
		display: flex;
		align-items: center;
		background-color: ${props => (props.invert ? colors.red : '#fff')};
	`,
	LogoContainer
};

export const CallButton = {
	Container: styled.a`
		padding: 6px 10px;
		padding-left: 7px;
		text-align: center;
		margin-right: 20px;
		font-size: 12px;
		font-weight: 600;
		border-radius: 3px;
		margin-left: auto;
		color: ${props => props.invert && '#fff'};
		fill: ${props => props.invert && '#fff'};
	`,
	Top: styled.div`
		display: flex;
		align-items: center;
	`,
	Text: styled.span`
		margin-left: 6px;
	`,
	Number: styled.div`
		font-size: 11px;
		color: ${props => (props.invert ? '#fff' : '#9d9d9d')};
		margin-top: -2px;
	`
};

const Line = styled.span`
	display: block;
	background-color: ${props => (props.isCross ? '#fff' : '#000')};
	height: 2px;
	width: 22px;
	left: 0;
	right: 0;
	margin: auto;
	position: absolute;
	transition: all 0.1s ${timingFunctions.standard};
`;

export const menuButton = {
	Container: styled.button`
		width: 48px;
		height: 100%;
		position: relative;

		&:after {
			content: '';
			opacity: ${props => (props.isCross ? '1' : '0')};
			position: absolute;
			width: 0;
			height: 0;
			left: 0;
			right: 0;
			margin: auto;
			bottom: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 6px solid #fff;
		}
	`,
	FirstLine: styled(Line)`
		margin-top: -9px;
		transform: ${props => props.isCross && 'translate(0, 9px) rotate(45deg)'};
	`,
	SecondLine: styled(Line)`
		margin-top: -0.67px;
		transform: ${props => props.isCross && 'scale(0,0)'};
	`,
	ThirdLine: styled(Line)`
		margin-top: 7px;
		transform: ${props => props.isCross && 'translate(0,-7px) rotate(-45deg)'};
	`,
	iconStyles: css`
		flex-shrink: 0;
	`
};

export const headerDesktop = {
	Container: styled.header`
		background-color: #fff;
	`,
	Content: styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 65px;
		padding-right: 65px;
		height: 78px;
		${mixins.center};
	`,
	LogoContainer
};
