import data from 'data';
import routes from 'routes';

export default {
	info: [
		{
			title: 'Dirección',
			text: data.address,
			href: data.addressHref
		},
		{
			title: 'Teléfono',
			text: data.phone,
			href: data.phoneHref
		},
		{
			title: 'WhatsApp',
			text: data.whatsapp,
			href: data.whatsappHref
		},
		{
			title: 'Mail',
			text: data.contactMail,
			href: `mailto:${data.contactMail}`
		}
	],
	links: [
		{
			text: 'Sobre nosotros',
			href: routes.aboutUs
		},
		{
			text: 'Preguntas frecuentes',
			href: routes.faq
		}
	]
};
