import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from './modules';

const middlewares = process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];

/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	rootReducer,
	undefined,
	composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
