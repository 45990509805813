import styled from 'styled-components';
import { colors } from 'theme';

export default {
	Container: styled.div`
		width: 80%;
		max-width: 315px;
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 19px;
		padding-bottom: 16px;
		padding-right: 24px;
		border: 1px solid ${colors.lightBlue};
		fill: ${colors.lightBlue};
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	IconContainer: styled.div`
		width: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
	TextContainer: styled.div`
		padding-left: 8px;
	`,
	Title: styled.div`
		font-size: 16px;
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		color: ${colors.lightBlue};
	`,
	Days: styled.div`
		font-size: 15px;
		margin-top: 2px;
	`,
	Schedule: styled.div`
		font-size: 14px;
		color: ${colors.grey2};
	`
};
