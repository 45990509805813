import * as types from './types';

const initialState = {
	products: [],
	sendingMessage: false,
	successModalIsOpen: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.ADD_PRODUCT:
			return {
				...state,
				products: [...state.products, action.product]
			};

		case types.REMOVE_PRODUCT:
			return {
				...state,
				products: state.products.filter(product => product.id !== action.id)
			};

		case types.SEND_MESSAGE_REQUEST:
			return {
				...state,
				sendingMessage: true
			};

		case types.SEND_MESSAGE_SUCCESS:
			return {
				...state,
				products: [],
				sendingMessage: false,
				successModalIsOpen: true
			};

		case types.CLOSE_SUCCESS_MODAL:
			return {
				...state,
				products: []
			};

		default:
			return state;
	}
}
