import styled from 'styled-components';
import { colors } from 'theme';

export default styled.textarea`
	border: 1px solid ${props => (props.error ? colors.red : colors.grey3)};
	height: 135px;
	border-radius: 2px;
	padding: 10px 20px;
	font-size: 15px;
`;
