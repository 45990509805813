import PropTypes from 'prop-types';

export const optionPropType = PropTypes.shape({
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const stylesPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
]);

export const matchPropType = PropTypes.shape({
	params: PropTypes.object,
	isExact: PropTypes.bool,
	path: PropTypes.string,
	url: PropTypes.string
});

export default {
	stylesPropType,
	matchPropType
};
