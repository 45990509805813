import React from 'react';
import PropTypes from 'prop-types';
import { stylesPropType } from 'utils/prop-types';
import icons from './icons.json';
import styled from './styles';

const Icon = ({ color, name, size, styles, pathStyles, ...props }) => {
	const icon = icons[name];

	if (!icon) return null;

	return (
		<styled.Svg
			width={size}
			height={size}
			styles={styles}
			color={color}
			viewBox={`0 0 ${icon.width} 1024`}
			size={size}
			{...props}
		>
			<styled.Path d={icon.path} styles={pathStyles} />
		</styled.Svg>
	);
};

Icon.propTypes = {
	name: PropTypes.oneOf(Object.keys(icons)).isRequired,
	color: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.number,
	styles: stylesPropType,
	pathStyles: stylesPropType
};

Icon.defaultProps = {
	size: 24
};

export default React.memo(Icon);
