import React from 'react';
import Icon from 'components/Icon';
import { emptyImage as styled } from './styles';

const EmptyImage = () => (
	<styled.Container>
		<Icon size={37} name="photo" />
		<styled.Text>IMAGEN NO DISPONIBLE</styled.Text>
	</styled.Container>
);

export default EmptyImage;
