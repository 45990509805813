import styled from 'styled-components';
import { colors } from 'theme';
import Button from 'components/Button';

export default {
	Container: styled.div`
		border-bottom: 1px solid #e8e8e8;
		padding: 26px 10px;
		color: ${colors.grey2};
		display: flex;
	`,
	TextContainer: styled.div`
		margin-left: 17px;
	`,
	Name: styled.div`
		font-size: 15px;
		margin-bottom: 15px;
		height: 47px;
	`,
	Button: styled(Button).attrs({
		variant: 'contained',
		color: 'primary'
	})`
		font-size: 14px;
		height: 35px;
		padding-left: 28px;
		padding-right: 28px;
	`
};

export const emptyImage = {
	Container: styled.div`
		background-color: ${colors.backgroundGrey};
		width: 115px;
		height: 110px;
		fill: ${colors.grey};
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	`,
	Text: styled.div`
		font-size: 11px;
		text-align: center;
		margin-top: 11px;
		color: ${colors.grey};
	`
};
