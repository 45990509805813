import * as types from './types';

export const openCallModal = () => ({
	type: types.OPEN_CALL_MODAL
});

export const closeCallModal = () => ({
	type: types.CLOSE_CALL_MODAL
});

export const openMenu = () => ({
	type: types.OPEN_MENU
});

export const closeMenu = () => ({
	type: types.CLOSE_MENU
});

export const toggleMenu = () => ({
	type: types.TOGGLE_MENU
});
