import React from 'react';
import menuitems from './menuItems';
import { menuDesktop as Styled } from './styles';

const MenuDesktop = () => (
	<Styled.Container>
		{menuitems
			.filter(item => !item.notVisibleOnDesktop)
			.map(item => (
				<Styled.Item to={item.href} key={item.text}>
					{item.text}
				</Styled.Item>
			))}
	</Styled.Container>
);

export default MenuDesktop;
