import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
.swiper-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 1;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
	transform: translate3d(0px, 0, 0);
}

.swiper-container-vertical>.swiper-wrapper {
	flex-direction: column;
}

.swiper-container-multirow>.swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
}

.swiper-slide-invisible-blank {
	visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight {
	height: auto;

	.swiper-slide {
		height: auto;
	}

	.swiper-wrapper {
		align-items: flex-start;
		transition-property: transform, height;
	}
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	width: 27px;
	height: 44px;
	margin-top: -22px;
	z-index: 10;
	cursor: pointer;
	background-size: 27px 44px;
	background-position: center;
	background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 0.35;
	cursor: auto;
	pointer-events: none;
}

.swiper-button-lock {
	display: none;
}

.swiper-pagination {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;

	&.swiper-pagination-hidden {
		opacity: 0;
	}
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
	bottom: 17px;
	left: 0;
	width: 100%;
}

.swiper-pagination-bullet {
	width: 5px;
	height: 5px;
	display: inline-block;
	border-radius: 100%;
	background: #fff;
	opacity: 0.23;
}

.swiper-pagination-bullet-active {
	width: 7px;
	height: 7px;
	opacity: 1;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
	overflow: hidden;
	font-size: 0;

	.swiper-pagination-bullet {
		transform: scale(0.33);
		position: relative;
	}

	.swiper-pagination-bullet-active,
	.swiper-pagination-bullet-active-main {
		transform: scale(1);
	}

	.swiper-pagination-bullet-active-prev {
		transform: scale(0.66);
	}

	.swiper-pagination-bullet-active-prev-prev {
		transform: scale(0.33);
	}

	.swiper-pagination-bullet-active-next {
		transform: scale(0.66);
	}

	.swiper-pagination-bullet-active-next-next {
		transform: scale(0.33);
	}
}

button.swiper-pagination-bullet {
	border: none;
	margin: 0;
	padding: 0;
	box-shadow: none;
	appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
}

.swiper-container-vertical>.swiper-pagination-bullets {
	right: 10px;
	top: 50%;
	transform: translate3d(0px, -50%, 0);

	.swiper-pagination-bullet {
		margin: 6px 0;
		display: block;
	}

	&.swiper-pagination-bullets-dynamic {
		top: 50%;
		transform: translateY(-50%);
		width: 8px;

		.swiper-pagination-bullet {
			display: inline-block;
			transition: 200ms transform, 200ms top;
		}
	}
}

.swiper-container-horizontal {
	> .swiper-pagination-bullets {
		.swiper-pagination-bullet { /* stylelint-disable-line */
			margin: 0 3px;
		}

		&.swiper-pagination-bullets-dynamic {
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;

			.swiper-pagination-bullet {
				transition: 200ms transform, 200ms left;
			}
		}
	}

	&.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transition: 200ms transform, 200ms right;
	}
}

.swiper-pagination-white .swiper-pagination-bullet-active {
	background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
	background: rgba(255, 255, 255, 0.25);

	.swiper-pagination-progressbar-fill {
		background: #ffffff;
	}
}

.swiper-pagination-black .swiper-pagination-bullet-active {
	background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
	background: rgba(0, 0, 0, 0.25);

	.swiper-pagination-progressbar-fill {
		background: #000000;
	}
}

.swiper-pagination-lock {
	display: none;
}

/* Scrollbar */
.swiper-scrollbar {
	border-radius: 10px;
	position: relative;
	background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal>.swiper-scrollbar {
	position: absolute;
	left: 1%;
	bottom: 3px;
	z-index: 50;
	height: 5px;
	width: 98%;
}

.swiper-container-vertical>.swiper-scrollbar {
	position: absolute;
	right: 3px;
	top: 1%;
	z-index: 50;
	width: 5px;
	height: 98%;
}

.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	left: 0;
	top: 0;
}

.swiper-scrollbar-cursor-drag {
	cursor: move;
}

.swiper-scrollbar-lock {
	display: none;
}

.swiper-container-fade {

	.swiper-slide {
		pointer-events: none;
		transition-property: opacity;

		.swiper-slide {
			pointer-events: none;
		}
	}

	&.swiper-container-free-mode .swiper-slide {
		transition-timing-function: ease-out;
	}

	.swiper-slide-active {
		pointer-events: auto;

		.swiper-slide-active {
			pointer-events: auto;
		}
	}
}
`;
