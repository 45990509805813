import React from 'react';
import { legal as Styled } from './styles';

const Legal = () => (
	<Styled.Container>
		<Styled.Content>© 2019 DEFA-REPUESTOS | TODOS LOS DERECHOS RESERVADOS</Styled.Content>
	</Styled.Container>
);

export default Legal;
