import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import data from 'data';
import { CallButton as styled } from './styles';

const CallButton = ({ invert }) => (
	<styled.Container href={data.celphoneHref} invert={invert}>
		<styled.Top>
			<Icon name="phone-call" size={13} />
			<styled.Text>LLAMAR</styled.Text>
		</styled.Top>
		<styled.Number invert={invert}>{data.celphone}</styled.Number>
	</styled.Container>
);

CallButton.propTypes = {
	invert: PropTypes.bool.isRequired
};

export default CallButton;
