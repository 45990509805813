import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/page';
import { menuButton as styled } from './styles';

const MenuButton = ({ isOpen, toggleMenu }) => (
	<styled.Container isCross={isOpen} onClick={toggleMenu}>
		<styled.FirstLine isCross={isOpen} />
		<styled.SecondLine isCross={isOpen} />
		<styled.ThirdLine isCross={isOpen} />
	</styled.Container>
);

MenuButton.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggleMenu: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isOpen: state.page.menuIsOpen
});

const mapDispatchToProps = {
	toggleMenu: actions.toggleMenu
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuButton);
