import styled from 'styled-components';
import { colors } from 'theme';

export default {
	Container: styled.div`
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		padding-top: 51px;
		padding-bottom: 36px;
	`,
	Top: styled.div`
		padding-left: 18px;
		margin-bottom: 34px;
	`,
	BackButton: styled.button`
		font-family: 'Roboto', sans-serif;
		display: flex;
		font-size: 16px;
		color: ${colors.red};
		fill: ${colors.red};
		align-items: center;
	`,
	BackButtonText: styled.span`
		margin-left: 16px;
	`,
	Title: styled.div`
		font-family: 'Roboto', sans-serif;
		margin-top: 4px;
		font-size: 23px;
		font-weight: 700;
	`
};
