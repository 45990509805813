import styled from 'styled-components';
import { mixins } from 'theme';

const Center = styled.div`
	${mixins.center};
	border-top: ${props => props.borderTopColor && `1px solid ${props.borderTopColor}`};
	border-bottom: ${props => props.borderBottomColor && `1px solid ${props.borderBottomColor}`};
`;

export default Center;
