import React from 'react';
import Swiper from 'react-id-swiper';
import darmet from 'images/brands/darmet.jpg';
import pistones from 'images/brands/persan.jpg';
import mahle from 'images/brands/mahle.svg';
import metalurgica from 'images/brands/metalurgica.jpg';
import Styled from './styles';
import SwiperStyles from '../SwiperStyles';

const sliderOptions = {
	slidesPerView: 'auto',
	spaceBetween: 15
};

const images = [
	{
		name: 'Darmet',
		src: darmet
	},
	{
		name: 'Metalúrgica BP',
		src: metalurgica
	},
	{
		name: 'Pistones Persan',
		src: pistones
	},
	{
		name: 'Mahle',
		src: mahle
	}
];

/* eslint-disable react/no-array-index-key */

const BestBrands = () => (
	<Styled.Container>
		<SwiperStyles />
		<Styled.Title>LAS MEJORES MARCAS</Styled.Title>
		<Styled.Text>
			Contamos con las marcas más importantes para nuestro gran catálogo de productos
		</Styled.Text>
		<Swiper {...sliderOptions}>
			{images.map((image, index) => (
				<Styled.Slide key={index}>
					<Styled.Img src={image.src} alt={image.name} />
				</Styled.Slide>
			))}
		</Swiper>
	</Styled.Container>
);

export default BestBrands;
