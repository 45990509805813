import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, mixins, media } from 'theme';

export default {
	Container: styled.section`
		padding-top: 35px;
		padding-bottom: 35px;
		background-color: ${colors.backgroundGrey};
		${mixins.center};
		${media.tablet`
			padding-top: 65px;
			padding-bottom: 54px;
		`};
	`,
	Title: styled.h2`
		font-family: 'Roboto', sans-serif;
		font-size: 18px;
		font-weight: 400;
		color: ${colors.grey2};

		${media.tablet`
			font-size: 19px;
		`}
	`,
	Categories: styled.div`
		display: grid;
		margin-top: 21px;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
		${media.tablet`
			grid-template-columns: repeat(6, 175px);
			justify-content: space-between;
			margin-top: 28px;
		`};
	`,
	SeeAll: styled(NavLink)`
		width: 100%;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Roboto', sans-serif;
		color: ${colors.lightBlue};
		margin-top: 17px;
		background-color: #ffffff;
		font-weight: 500;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
	`
};

export const category = {
	Container: styled(NavLink)`
		background-color: #fff;
		border-radius: 4px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
		color: ${colors.grey2};
		font-size: 15px;
		text-align: center;
		padding-top: 15px;
		padding-bottom: 26px;

		${media.tablet`
			font-size: 16px;
			padding-top: 16px;
			padding-bottom: 32px;
		`}

		${props =>
			!props.imageSrc &&
			css`
				min-height: 163px;
				display: flex;
				align-items: center;
				justify-content: center;
			`}
	`,
	Img: styled.img`
		height: 100px;
		width: auto;
	`
};
