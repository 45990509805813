import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { MediaMobile } from 'components/Media';
// import Link from 'components/Link';
import { feature as styled } from './styles';

const Feature = ({ id, icon, iconSize, iconSizeDesktop, title, description /* link */ }) => (
	<styled.Container id={id}>
		<styled.IconContainer>
			<MediaMobile>
				{matches => <Icon size={matches ? iconSize : iconSizeDesktop} name={icon} />}
			</MediaMobile>
		</styled.IconContainer>
		<styled.Title>{title}</styled.Title>
		<styled.Description>{description}</styled.Description>
		{/* linkText && <Link to={link}>Más info</Link> */}
	</styled.Container>
);

Feature.propTypes = {
	id: PropTypes.string,
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number.isRequired,
	iconSizeDesktop: PropTypes.number.isRequired,
	title: PropTypes.string,
	description: PropTypes.string.isRequired
	// link: PropTypes.string
};

export default Feature;
