import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		border: 0;
	}

	body {
		margin: 0;
		padding: 0;
		font-family: 'Open Sans', sans-serif;
	}

	button {
		background: none;
		outline: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		border: 0;

		&:disabled {
			cursor: default;
		}
	}

	a,
	image {
		display: inline-block;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	button {
		color: inherit;
	}

	button,
	input,
	textarea {
		font-family: inherit;
	}

	textarea:focus,
	input:focus {
		outline: none;
	}

	textarea {
		resize: none;
	}
`;
