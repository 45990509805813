import { css } from 'styled-components';

const size = {
	mobile: '699px',
	tablet: '700px',
	desktop: '1024px',
	desktopB: '1600px'
};

export const queries = {
	mobile: `(max-width: ${size.mobile})`,
	tablet: `(min-width: ${size.tablet})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopB: `(min-width: ${size.desktopB})`
};

const media = {
	mobile: (...args) => css`
		@media ${queries.mobile} {
			${css(...args)}
		}
	`,
	tablet: (...args) => css`
		@media ${queries.tablet} {
			${css(...args)}
		}
	`,
	desktop: (...args) => css`
		@media ${queries.desktop} {
			${css(...args)}
		}
	`,
	desktopB: (...args) => css`
		@media ${queries.desktopB} {
			${css(...args)}
		}
	`
};

export default media;
