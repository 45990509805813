import React from 'react';
import data from 'data';
import styled from './styles';
import SocialNetworks from './SocialNetworks';
import Legal from './Legal';

const Footer = () => (
	<styled.Container>
		<styled.Content>
			<SocialNetworks />
			<styled.Groups>
				<styled.Group>
					<styled.GroupTitle>Dirección</styled.GroupTitle>
					<styled.GroupItem>
						<a href={data.addressHref}>{data.address}</a>
					</styled.GroupItem>
				</styled.Group>
				<styled.Group>
					<styled.GroupTitle>Contacto</styled.GroupTitle>
					<styled.GroupItem>
						Teléfono: <a href={data.phoneHref}>{data.phone}</a>
					</styled.GroupItem>
					<styled.GroupItem whatsapp>
						<span className="whatsapp-text">WhatsApp: </span>
						<span>
							<a className="whatsapp-link" href={data.whatsappHref}>
								{data.whatsapp}
							</a>
						</span>
					</styled.GroupItem>
					<styled.GroupItem>
						Mail: <a href={`mailto:${data.contactMail}`}>{data.contactMail}</a>
					</styled.GroupItem>
				</styled.Group>
				<styled.Group>
					<a href="/">Sobre nosotros</a>
				</styled.Group>
				{/* <styled.Group>
					<a href="/">Preguntas Frecuentes</a>
				</styled.Group> */}
			</styled.Groups>
		</styled.Content>
		<Legal />
	</styled.Container>
);

export default Footer;
