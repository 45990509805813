import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const coords = { lat: -34.634543, lng: -58.39854 };

const Map = () => (
	<GoogleMap
		defaultZoom={16}
		defaultCenter={coords}
		defaultOptions={{
			streetViewControl: false,
			mapTypeControl: false
		}}
	>
		<Marker position={coords} />
	</GoogleMap>
);

export default withScriptjs(withGoogleMap(Map));
