import styled from 'styled-components';

export default styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	justify-content: center;
	column-gap: 19px;
`;

export const socialNetwork = {
	Container: styled.a`
		width: 36px;
		height: 36px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
	`
};
