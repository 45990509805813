import React from 'react';
// import Link from 'components/Link';
import { aboutDefa as styled } from './styles';

const AboutDefa = () => (
	<styled.Container id="sobre-defa">
		<styled.Title>SOBRE DEFA</styled.Title>
		<styled.Text>
			Desde el 01 de Abril de 1985, DEFA, ofrece repuestos para motores y tractores Deutz a todo el
			pais. Uno de los objetivos fue contar con una gran variedad de productos a disposición del
			cliente y al mejor precio. Ahora el objetivo es ofrecer nuevos medios para resolver las
			necesidades del cliente de una manera fácil y sencilla.
		</styled.Text>
		{/* <Link to="/sobre-defa">Conocer más</Link> */}
	</styled.Container>
);

export default AboutDefa;
