import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import { category as styled } from './styles';

const Category = ({ imageSrc, name }) => (
	<styled.Container
		to={routes.products.replace(':category', name.toLowerCase())}
		imageSrc={imageSrc}
	>
		{imageSrc && <styled.Img src={imageSrc} alt={name} />}
		<div>{name}</div>
	</styled.Container>
);

Category.propTypes = {
	imageSrc: PropTypes.string,
	name: PropTypes.string.isRequired
};

export default Category;
