import React from 'react';
import PageModal from 'components/PageModal';
import Center from 'components/Center';
import categoryGroups from 'data/categories.json';
import { categories as styled } from './styles';
import Category from './Category';

const Categories = () => (
	<PageModal title="Elige una categoría">
		<Center>
			<div>
				{categoryGroups.map((categories, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<styled.Group key={index}>
						{categories.map(category => (
							<Category key={category} name={category} />
						))}
					</styled.Group>
				))}
			</div>
		</Center>
	</PageModal>
);

export default Categories;
