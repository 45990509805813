import React from 'react';
import Swiper from 'react-id-swiper';
import { NavLink } from 'react-router-dom';
import banner from 'images/banners/repuestos.jpg';
import bannerX2 from 'images/banners/repuestosx2.jpg';
import bannerX4 from 'images/banners/repuestosx4.jpg';
import banner2 from 'images/banners/repuestos2.png';
import { bannerSlider as styled } from './styles';
import SwiperStyles from './SwiperStyles';

const sliderOptions = {
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true
	},
	effect: 'fade',
	spaceBetween: 30
};

const BannerSlider = () => (
	<>
		<SwiperStyles />
		<Swiper {...sliderOptions}>
			<styled.SlideWithoutText image={banner} imageX2={bannerX2} imageX4={bannerX4}>
				<NavLink to="/solicitar">
					<styled.Button>SOLICITAR</styled.Button>
				</NavLink>
			</styled.SlideWithoutText>

			<styled.Slide image={banner2}>
				<styled.Title>REPUESTOS DEUTZ</styled.Title>
				<styled.Text>+5000 respuestos con envíos a todo el país</styled.Text>
			</styled.Slide>
		</Swiper>
	</>
);

export default BannerSlider;
