import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import { colors, mixins, media } from 'theme';

export default styled.div`
	${media.tablet`
		background-color: ${colors.backgroundGrey};
	`}
`;

export const searchBar = {
	Container: styled.div`
		padding-top: 12px;
		padding-bottom: 12px;
		background-color: ${colors.backgroundGrey};
		${mixins.center};
	`,
	InputContainer: styled.label`
		width: 100%;
		background-color: #fff;
		height: 42px;
		border-radius: 2px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		padding-left: 13px;
	`,
	Input: styled.input`
		height: 100%;
		margin-left: 12px;
		flex-grow: 1;
		font-size: 16px;
	`
};

export const WhatsappButtonStyled = styled(Fab).attrs({
	color: 'primary'
})`
	position: fixed;
	z-index: 10;
	left: 12px;
	bottom: 12px;
	fill: #fff;
`;
