export default {
	home: '/',
	aboutUs: '/?section=sobre-defa',
	contact: '/contacto',
	categories: '/repuestos',
	products: '/repuestos/:category',
	faq: '/preguntas-frecuentes',
	call: '/solicitar',
	productRequest: '/solicitar-precio'
};
