import { combineReducers } from 'redux';
import page from './page';
import home from './home';
import productRequest from './productRequest';

export default combineReducers({
	page,
	home,
	productRequest
});
