import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styled from './styles';

const SuccessModal = ({ isOpen, closeModal, children, text, iconName }) => (
	<styled.Modal isOpen={isOpen} onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
		<Icon name={iconName} size={73} />
		<styled.Title>MENSAJE ENVIADO</styled.Title>
		<styled.Text>{text}</styled.Text>
		{children}
	</styled.Modal>
);

SuccessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	text: PropTypes.string,
	iconName: PropTypes.string
};

SuccessModal.defaultProps = {
	iconName: 'correct',
	text: 'Te responderemos a la brevedad'
};

export default SuccessModal;
