import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import history from 'utils/history';
import styled from './styles';

const PageModal = ({ title, children }) => (
	<styled.Container>
		<styled.Top>
			<styled.BackButton onClick={history.goBack}>
				<Icon name="back" size={20} />
				<styled.BackButtonText>Volver</styled.BackButtonText>
			</styled.BackButton>
			<styled.Title>{title}</styled.Title>
		</styled.Top>
		{children}
	</styled.Container>
);

PageModal.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default PageModal;
