import React from 'react';
import data from 'data';
import Option from './Option';
import { StyledOptions } from './styles';

const Options = () => (
	<StyledOptions>
		<Option icon="phone" iconSize={29} title="Teléfono" text={data.phone} href={data.phoneHref} />
		<Option
			icon="smartphone"
			iconSize={34}
			title="Celular"
			text={data.celphone}
			href={data.celphoneHref}
		/>
		<Option
			icon="email"
			iconSize={30}
			title="Mail"
			text={data.contactMail}
			href={`mailto:${data.contactMail}`}
		/>
		<Option
			icon="placeholder"
			iconSize={30}
			title="Dirección"
			text={data.address}
			href={data.addressHref}
		/>
	</StyledOptions>
);

export default Options;
