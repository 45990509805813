import styled from 'styled-components';

const styles = {
	Svg: styled.svg`
		fill: ${props => props.color || 'inherit'};
		${props => props.styles};
	`,
	Path: styled.path`
		fill: inherit;
		${props => props.pathStyles};
	`
};

export default styles;
