import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Page from 'components/Page';
import { getProducts } from 'api';
import Product from './Product';

const Products = ({ match }) => {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		const fetchProducts = async () => {
			const response = await getProducts();
			setProducts(response[match.params.category] || []);
		};

		fetchProducts();
	}, []);

	return (
		<Page>
			{products.map(product => (
				<Product key={product.id} {...product} />
			))}
		</Page>
	);
};

Products.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			category: PropTypes.string.isRequired
		})
	}).isRequired
};

export default withRouter(Products);
