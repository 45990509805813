import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { colors } from 'theme';

export default {
	Modal: Modal.styled`
		width: 94%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		padding: 45px 0;
		fill: ${colors.lightBlue};
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Title: styled.div`
		margin-top: 29px;
		font-family: 'Roboto', sans-serif;
		font-size: 22px;
		font-weight: 700;
		color: ${colors.lightBlue};
	`,
	Text: styled.div`
		margin-top: 10px;
		color: ${colors.grey};
		font-size: 14px;
		height: 37px;
		margin-bottom: 27px;
	`
};
