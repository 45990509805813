import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { object, string } from 'yup';
import Page from 'components/Page';
import { FieldInput, FieldTextarea } from 'components/Field';
import Icon from 'components/Icon';
import { actions } from 'modules/productRequest';
import SuccessModal from './SuccessModal';
import Product from './Product';
import styled from './styles';

const validationSchema = object().shape({
	name: string().required('Campo requerido.'),
	email: string()
		.email('Email inválido.')
		.required('Campo requerido.'),
	message: string()
});

const ProductRequest = ({ sendMessage, buttonDisabled, products }) => (
	<Page>
		<SuccessModal />
		<styled.Container>
			<styled.Title>Completa el formulario</styled.Title>
			<styled.Message>
				Indique en el mensaje la cantidad a consultar del producto y/o la información que desee
				saber. Te responderemos a la brevedad.
			</styled.Message>
			<Formik
				initialValues={{ name: '', email: '', phone: '', message: '' }}
				validationSchema={validationSchema}
				onSubmit={(values, { resetForm }) => {
					sendMessage(values, resetForm);
				}}
			>
				{({ values, handleSubmit, handleChange, handleBlur }) => (
					<styled.Form onSubmit={handleSubmit}>
						<styled.InputsContainer>
							<Field
								component={FieldInput}
								name="name"
								placeholder="Nombre y apellido"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.name}
							/>
							<Field
								component={FieldInput}
								name="email"
								placeholder="Correo electrónico"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
							/>
							<Field
								component={FieldInput}
								name="phone"
								placeholder="Teléfono"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.phone}
							/>
							<Field
								component={FieldTextarea}
								name="message"
								placeholder="Mensaje"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.message}
							/>
						</styled.InputsContainer>
						<styled.ProductsTitleContainer>
							<span>Productos a consultar</span>
							{/* <span>+Agregar otro</span> */}
						</styled.ProductsTitleContainer>
						<styled.ProductsContainer>
							{products.map(product => (
								<Product key={product.id} {...product} />
							))}
						</styled.ProductsContainer>
						<styled.Button disabled={buttonDisabled}>
							<Icon name="send" size={20} color="#fff" />
							<styled.ButtonText>ENVIAR</styled.ButtonText>
						</styled.Button>
					</styled.Form>
				)}
			</Formik>
		</styled.Container>
	</Page>
);

ProductRequest.propTypes = {
	buttonDisabled: PropTypes.bool.isRequired,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string
		})
	).isRequired,
	sendMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	products: state.productRequest.products,
	buttonDisabled: state.productRequest.sendingMessage
});

const mapDispatchToProps = {
	sendMessage: actions.sendMessage
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductRequest);
