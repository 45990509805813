import React from 'react';
import Page from 'components/Page';
import Center from 'components/Center';
import Options from './Options';
import Map from './Map';
import styled from './styles';

const Contact = () => (
	<Page>
		<Center>
			<styled.Container>
				<styled.Top>
					<styled.Title>Contacto</styled.Title>
					<styled.Schedule>
						Estamos de Lunes a Viernes a partir de las 08:30 a 13:00 y de 14:00 a 18:00
					</styled.Schedule>
					<styled.Bottom>
						<Options />
						<Map
							googleMapURL="https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyDtnwODdBT3RiPWYwr-BKv0WRLaEtFavzk"
							loadingElement={<styled.MapContainer />}
							containerElement={<styled.MapContainer />}
							mapElement={<div style={{ height: `100%` }} />}
						/>
					</styled.Bottom>
					{/* <styled.Map
					src="https://www.google.com/maps/embed/view?key=AIzaSyDtnwODdBT3RiPWYwr-BKv0WRLaEtFavzk&zoom=20&center=-33.8569,151.2152"
					height="224"
					frameBorder="0"
					style={{ border: 0 }}
					allowFullScreen
					title="map"
				/> */}
				</styled.Top>
			</styled.Container>
		</Center>
	</Page>
);

export default Contact;
