import { sendContactMessage } from 'api';
import * as types from './types';

const sendMessageRequest = () => ({
	type: types.SEND_MESSAGE_REQUEST
});
const sendMessageSuccess = () => ({
	type: types.SEND_MESSAGE_SUCCESS
});

export const sendMessage = (data, resetForm) => async dispatch => {
	dispatch(sendMessageRequest());

	await sendContactMessage(data);
	resetForm();
	dispatch(sendMessageSuccess());
};

export const closeContactModal = () => ({
	type: types.CLOSE_CONTACT_MODAL
});
