import * as types from './types';

const initialState = {
	sendingContactMessage: false,
	contactModalIsOpen: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.SEND_MESSAGE_REQUEST:
			return {
				...state,
				sendingContactMessage: true
			};

		case types.SEND_MESSAGE_SUCCESS:
			return {
				...state,
				sendingContactMessage: false,
				contactModalIsOpen: true
			};

		case types.CLOSE_CONTACT_MODAL:
			return {
				...state,
				contactModalIsOpen: false
			};

		default:
			return state;
	}
}
