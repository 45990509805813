import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const categories = {
	Group: styled.div`
		border-radius: 5px;
		border: 1px solid #d5d5d5;
		background-color: #fbfbfb;
		margin-bottom: 25px;
	`
};

export const category = {
	Container: styled(NavLink)`
		height: 60px;
		border-bottom: 1px solid #e8e8e8;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 15px;
		padding-left: 16px;
		padding-right: 27px;

		&:last-child {
			border-bottom: none;
		}
	`,
	See: styled.div`
		display: flex;
		align-items: center;
		color: #949494;
		fill: #949494;
	`,
	SeeText: styled.span`
		font-size: 13px;
		margin-right: 14px;
	`
};

export const products = {
	Container: styled.div`
		border-top: 1px solid #e8e8e8;
	`
};
