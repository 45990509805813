import React from 'react';
import logo from 'images/logo.svg';
import { headerDesktop as Styled } from './styles';
import { MenuDesktop } from '../Menu';

const HeaderDesktop = () => (
	<Styled.Container>
		<Styled.Content>
			<Styled.LogoContainer to="/">
				<img src={logo} width="106" height="30" alt="DEFA logo" />
			</Styled.LogoContainer>
			<MenuDesktop />
		</Styled.Content>
	</Styled.Container>
);

export default HeaderDesktop;
