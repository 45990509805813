import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions } from 'modules/productRequest';
import history from 'utils/history';
import styled from './styles';
import EmptyImage from './EmptyImage';

const Product = ({ id, name, image, addProduct }) => (
	<styled.Container>
		{image ? 'image' : <EmptyImage />}
		<styled.TextContainer>
			<styled.Name> {name} </styled.Name>

			<styled.Button
				onClick={() => {
					addProduct({ id, name });
					history.push('/solicitar-precio');
				}}
			>
				SOLICITAR PRECIO
			</styled.Button>
		</styled.TextContainer>
	</styled.Container>
);

Product.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	image: PropTypes.string,
	addProduct: PropTypes.func.isRequired
};

const mapDispatchToProps = {
	addProduct: actions.addProduct
};

export default connect(
	null,
	mapDispatchToProps
)(Product);
