import React from 'react';
import radiadores from 'images/categories/radiadores.jpg';
import turboventiladores from 'images/categories/turboventiladores.jpg';
import rotores from 'images/categories/rotores.jpg';
import engranajes from 'images/categories/engranajes.jpg';
import conjuntos from 'images/categories/conjuntos.jpg';
import juegosDeJuntas from 'images/categories/juegos-de-juntas.jpg';
import styled from './styles';
import Category from './Category';

const MostRequested = () => (
	<styled.Container>
		<styled.Title>Categorías populares</styled.Title>
		<styled.Categories>
			<Category name="Radiadores" imageSrc={radiadores} />
			<Category name="Turboventiladores" imageSrc={turboventiladores} />
			<Category name="Rotores" imageSrc={rotores} />
			<Category name="Engranajes" imageSrc={engranajes} />
			<Category name="Conjuntos" imageSrc={conjuntos} />
			<Category name="Juegos de juntas" imageSrc={juegosDeJuntas} />
		</styled.Categories>
		{/* <styled.SeeAll to="/">Ver todo</styled.SeeAll> */}
	</styled.Container>
);

export default MostRequested;
