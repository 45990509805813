import React from 'react';
import Center from 'components/Center';
import Icon from 'components/Icon';
import Schedule from 'components/Schedule';
import history from 'utils/history';
import Options from './Options';
import styled from './styles';

const Call = () => (
	<styled.Container>
		<Center>
			<styled.Top>
				<styled.BackButton onClick={history.goBack}>
					<Icon name="back" size={20} />
					<styled.BackButtonText>Volver</styled.BackButtonText>
				</styled.BackButton>
				<styled.Title>Elige una opción</styled.Title>
			</styled.Top>
		</Center>
		<Options />
		<Schedule />
	</styled.Container>
);

export default Call;
