import React from 'react';
import Textarea from 'components/Textarea';
import { StyledError, StyledFieldContainer } from './styles';

// eslint-disable-next-line react/prop-types
const FieldTextarea = ({ field, form, ...props }) => (
	<StyledFieldContainer>
		<Textarea error={form.submitCount && !!form.errors[field.name]} {...field} {...props} />
		{form.submitCount && form.errors[field.name] ? (
			<StyledError>{form.errors[field.name]}</StyledError>
		) : null}
	</StyledFieldContainer>
);

export default FieldTextarea;
