import styled from 'styled-components';
import { colors } from 'theme';
import { NavLink } from 'react-router-dom';

export default {
	Container: styled.nav`
		display: ${props => (props.isVisible ? 'block' : 'none')};
		padding-top: 16px;
		padding-bottom: 92px;
		background-color: #fff;
	`
};

export const item = {
	Container: styled(NavLink)`
		font-family: 'Roboto', sans-serif;
		height: 50px;
		padding-left: 42px;
		font-size: 16px;
		fill: ${colors.red};
		display: flex;
		align-items: center;
	`,
	IconContainer: styled.div`
		width: 20px;
		display: flex;
		justify-content: center;
		margin-right: 23px;
		flex-shrink: 0;
	`
};

export const menuDesktop = {
	Container: styled.nav`
		width: 41%;
		min-width: 420px;
		max-width: 520px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
	`,
	Item: styled(NavLink)`
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		padding: 5px;
	`
};
