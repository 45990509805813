import React, { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { object, string } from 'yup';
import { FieldInput, FieldTextarea } from 'components/Field';
import Icon from 'components/Icon';
import { actions } from 'modules/home';
import styled from './styles';
import SuccessModal from './SuccessModal';

const validationSchema = object().shape({
	name: string().required('Campo requerido.'),
	email: string()
		.email('Email inválido.')
		.required('Campo requerido.'),
	message: string().required('Campo requerido.')
});

const Contact = ({ buttonDisabled, sendMessage, location }) => {
	const nameInput = useRef(null);
	useEffect(
		() => {
			if (location.search === '?section=consulta' && nameInput.current) {
				nameInput.current.focus();
			}
		},
		[location]
	);

	return (
		<section>
			<Formik
				initialValues={{ name: '', email: '', phone: '', message: '' }}
				validationSchema={validationSchema}
				onSubmit={(values, { resetForm }) => {
					sendMessage(values, resetForm);
				}}
			>
				{({ values, handleSubmit, handleChange, handleBlur }) => (
					<styled.Container onSubmit={handleSubmit}>
						<SuccessModal />
						<styled.Title>ESCRIBE TÚ CONSULTA</styled.Title>
						<styled.InputsContainer>
							<Field
								innerRef={nameInput}
								component={FieldInput}
								name="name"
								placeholder="Nombre y apellido"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.name}
							/>
							<Field
								component={FieldInput}
								name="email"
								placeholder="Correo electrónico"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
							/>
							<Field
								component={FieldInput}
								name="phone"
								placeholder="Teléfono"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.phone}
							/>
							<Field
								component={FieldTextarea}
								name="message"
								placeholder="Mensaje"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.message}
							/>
						</styled.InputsContainer>
						<styled.Button disabled={buttonDisabled}>
							<Icon name="send" size={20} color="#fff" />
							<styled.ButtonText>ENVIAR</styled.ButtonText>
						</styled.Button>
					</styled.Container>
				)}
			</Formik>
		</section>
	);
};

Contact.propTypes = {
	buttonDisabled: PropTypes.bool.isRequired,
	sendMessage: PropTypes.func.isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	buttonDisabled: state.home.sendingContactMessage
});

const mapDispatchToProps = {
	sendMessage: actions.sendMessage
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Contact)
);
