import React from 'react';
import Feature from './Feature';
import featureItems from './featureItems';
import StyledFeatures from './styles';

const Features = () => (
	<StyledFeatures>
		{featureItems.map(feature => (
			<Feature key={feature.icon} {...feature} />
		))}
	</StyledFeatures>
);

export default Features;
