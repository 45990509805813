import React from 'react';
import { Provider } from 'react-redux';
import { ModalProvider } from 'styled-react-modal';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'utils/history';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { hot, setConfig } from 'react-hot-loader';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Home from 'pages/Home';
import Contact from 'pages/Contact';
import Call from 'pages/Call';
import ProductRequest from 'pages/ProductRequest';
import Categories from 'pages/Repuestos/Categories';
import Products from 'pages/Repuestos/Products';
import { materialTheme } from 'theme';
import routes from 'routes';
import Reset from './global-styles/Reset';
import store from './redux-store';

setConfig({ logLevel: 'no-errors-please' });

const generateClassName = createGenerateClassName();
const jss = create({
	...jssPreset(),
	// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
	insertionPoint: document.getElementById('jss-insertion-point')
});

const App = () => (
	<Provider store={store}>
		<Router history={history}>
			<ModalProvider>
				<JssProvider jss={jss} generateClassName={generateClassName}>
					<MuiThemeProvider theme={materialTheme}>
						<Reset />
						<Switch>
							<Route exact path={routes.home} component={Home} />
							<Route path={routes.contact} component={Contact} />
							<Route path={routes.products} component={Products} />
							<Route path={routes.categories} component={Categories} />
							<Route path={routes.call} component={Call} />
							<Route path={routes.productRequest} component={ProductRequest} />
							<Route component={Home} />
						</Switch>
					</MuiThemeProvider>
				</JssProvider>
			</ModalProvider>
		</Router>
	</Provider>
);

export default hot(module)(App);
