import React from 'react';
import data from 'data';
import SocialNetwork from './SocialNetwork';
import StyledSocialNetworks from './styles';

const SocialNetworks = () => (
	<StyledSocialNetworks>
		<SocialNetwork icon="facebook2" iconSize={19} href={data.facebook} />
		<SocialNetwork icon="instagram" iconSize={22} href={data.instagram} />
	</StyledSocialNetworks>
);

export default SocialNetworks;
