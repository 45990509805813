import React from 'react';
import Center from 'components/Center';
import Icon from 'components/Icon';
import { shipping as styled } from './styles';

const Shipping = () => (
	<Center>
		<styled.Container>
			<styled.IconWrapper>
				<Icon name="delivery2" size={28} />
			</styled.IconWrapper>
			<styled.TextWrapper>
				<styled.Text>Envíos a todo el país</styled.Text>
				<styled.Link href="#envios">Conocer más</styled.Link>
			</styled.TextWrapper>
		</styled.Container>
	</Center>
);

export default Shipping;
