import React from 'react';
import Icon from 'components/Icon';
import styled from './styles';

const Schedule = () => (
	<styled.Container>
		<styled.IconContainer>
			<Icon size={26} name="clock" />
		</styled.IconContainer>
		<styled.TextContainer>
			<styled.Title>HORARIO DE ATENCIÓN</styled.Title>
			<styled.Days>Lunes a Viernes</styled.Days>
			<styled.Schedule>08:30 a 13:00 | 14:00 a 18:00</styled.Schedule>
		</styled.TextContainer>
	</styled.Container>
);

export default Schedule;
