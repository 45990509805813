import styled from 'styled-components';
import { colors } from 'theme';

const CONTAINER_PIXELS_BY_SIZE = {
	small: 65,
	normal: 115
};

const MARGIN_BY_SIZE = {
	small: 6,
	normal: 11
};

const TEXT_PIXELS_BY_SIZE = {
	small: 10,
	normal: 11
};

export default {
	Container: styled.div`
		background-color: ${colors.backgroundGrey};
		width: ${props => CONTAINER_PIXELS_BY_SIZE[props.size]}px;
		height: ${props => Math.round(CONTAINER_PIXELS_BY_SIZE[props.size] * 0.96)}px;
		fill: ${colors.grey};
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	`,
	Text: styled.div`
		font-size: ${props => TEXT_PIXELS_BY_SIZE[props.size]}px;
		text-align: center;
		margin-top: ${props => MARGIN_BY_SIZE[props.size]}px;
		color: ${colors.grey};
	`
};
