import styled from 'styled-components';
import Button from 'components/Button';
import { colors, mixins } from 'theme';

export default {
	Container: styled.div`
		padding: 30px 25px 100px;

		input,
		textarea {
			width: 100%;
		}
	`,
	Title: styled.h1`
		${mixins.title};
		font-size: 23px;
	`,
	Message: styled.div`
		color: ${colors.grey};
		font-size: 14px;
		margin-top: 7px;
		margin-bottom: 31px;
	`,
	Form: styled.form`
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	InputsContainer: styled.div`
		display: grid;
		row-gap: 30px;
		margin-bottom: 36px;
		width: 100%;
	`,
	ProductsTitleContainer: styled.div`
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-size: 14px;
		margin-bottom: 12px;
	`,
	ProductsContainer: styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
	Button: styled(Button).attrs({
		variant: 'contained',
		color: 'primary',
		type: 'submit'
	})`
		width: 100%;
		max-width: 300px;
		height: 40px;
		margin-top: 50px;
	`,
	ButtonText: styled.span`
		margin-left: 9px;
	`
};

export const product = {
	Container: styled.div`
		display: flex;
		align-items: center;
		position: relative;
		padding: 4px 14px 4px 10px;
		border: 1px solid ${colors.grey3};
		border-radius: 2px;

		&:not(:last-child) {
			border-bottom: none;
		}
	`,
	CloseButton: styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		cursor: pointer;
		background-color: #000;
		fill: #fff;
		position: absolute;
		top: -9px;
		left: -9px;
	`,
	Text: styled.div`
		padding-left: 24px;
		font-size: 14px;
	`
};
