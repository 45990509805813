import axios from 'axios';
import store from 'store';
import expirePlugin from 'store/plugins/expire';

store.addPlugin(expirePlugin);
const EXPIRATION_DAYS = 7;

export const sendContactMessage = data => axios.post('/api/send-contact-message', data);

export const getProducts = async () => {
	let products = store.get('products');

	if (!products) {
		({ data: products } = await axios.get(`${process.env.PUBLIC_URL}/products.json`));

		const expirationTime = new Date().getTime() + 1000 * 60 * 60 * 24 * EXPIRATION_DAYS;
		store.set('products', products, expirationTime);
	}

	return products;
};
