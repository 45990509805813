import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import menuItems from './menuItems';
import Item from './Item';
import styled from './styles';

const Menu = ({ isVisible }) => (
	<styled.Container isVisible={isVisible}>
		{menuItems.map(item => (
			<Item key={item.text} {...item} />
		))}
		{/* <Item href="/" icon="login" iconSize={19} text="Ingresar" /> */}
	</styled.Container>
);

Menu.propTypes = {
	isVisible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isVisible: state.page.menuIsOpen
});

export default connect(mapStateToProps)(Menu);
