import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from 'images/logo.svg';
import logoInvert from 'images/logo-negativo.svg';
import styled from './styles';
import CallButton from './CallButton';
import MenuButton from './MenuButton';

const Header = ({ invert }) => (
	<styled.Container invert={invert}>
		<styled.LogoContainer to="/">
			<img src={invert ? logoInvert : logo} width="106" height="30" alt="DEFA logo" />
		</styled.LogoContainer>
		<CallButton invert={invert} />
		<MenuButton />
	</styled.Container>
);

Header.propTypes = {
	invert: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	invert: state.page.menuIsOpen
});

export default connect(mapStateToProps)(Header);
