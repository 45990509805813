import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/page';
import { option as styled } from './styles';

const Option = ({ closeModal, href, text, smallText, renderIcon }) => (
	<styled.Container href={href} onClick={closeModal}>
		<styled.IconContainer>{renderIcon()}</styled.IconContainer>
		<styled.Text>
			{text}
			{smallText && <styled.SmallText>{smallText}</styled.SmallText>}
		</styled.Text>
	</styled.Container>
);

Option.propTypes = {
	closeModal: PropTypes.func.isRequired,
	href: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	smallText: PropTypes.string,
	renderIcon: PropTypes.func.isRequired
};

const mapDispatchToProps = {
	closeModal: actions.closeCallModal
};

export default connect(
	null,
	mapDispatchToProps
)(Option);
