import { css } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

import media, { queries } from './media';

const materialTheme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#ff0d00',
			light: '#FF534A'
		},
		secondary: {
			main: '#1725d3'
		}
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 3,
				fontSize: 16,
				padding: '4px 16px'
			},
			outlined: {
				height: 45,
				paddingLeft: 27,
				paddingRight: 27
			},
			contained: {
				boxShadow: 'none',
				'&:active': {
					boxShadow: 'none'
				}
			}
		}
	}
});

const timingFunctions = {
	standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
	decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
	accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)'
};

const centerPadding = '10px';
const centerPaddingTablet = '25px';
const centerPaddingDesktop = '50px';

const theme = {
	colors: {
		grey: '#949494',
		grey2: '#7A7A7A',
		grey3: '#D5D5D5',
		darkGrey: '#828282',
		backgroundGrey: '#f2f1f1',
		red: '#ff0d00',
		red2: '#FF534A',
		blue: '#1725d3',
		lightBlue: '#3F89F9'
	},
	transition: `all 250ms ${timingFunctions.standard}`,
	timingFunctions,
	mixins: {
		title: css`
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
		`,
		flexCenter: css`
			display: flex;
			align-items: center;
			justify-content: center;
		`,
		center: css`
			padding-left: ${centerPadding};
			padding-right: ${centerPadding};
			width: 100%;

			${media.tablet`
				padding-left: ${centerPaddingTablet};
				padding-right: ${centerPaddingTablet};
			`};

			${media.desktop`
				padding-left: ${centerPaddingDesktop};
				padding-right: ${centerPaddingDesktop};
				max-width: 1280px;
				margin-left: auto;
				margin-right: auto;
			`};
		`,
		desktopMaxWidth: css`
			max-width: 1280px;
			margin: auto;
		`,
		placeholder(styles) {
			return css`
				&::-moz-placeholder {
					${styles}
				}
				&::-webkit-input-placeholder {
					${styles}
				}
				&:-moz-placeholder {
					${styles}
				}
				&:-ms-input-placeholder {
					${styles}
				}
				&::placeholder {
					${styles}
				}
			`;
		},
		transition(property = 'all', time = '200ms') {
			let transitionProperty;
			if (property.includes(',')) transitionProperty = `transition-property: ${property}`;

			const transition = `${property.split(',')[0]} ${time} ${timingFunctions.standard}`;

			return css`
				transition: ${transition};
				${transitionProperty};
			`;
		}
	}
};

export default theme;

export const { colors, transition, mixins } = theme;

export { timingFunctions, materialTheme, centerPadding, media, queries };
