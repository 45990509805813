import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import { MediaMobile } from 'components/Media';
import { smoothScroll } from 'utils/browser';
import Styled from './styles';
import BannerSlider from './BannerSlider';
import BannerSliderDesktop from './BannerSliderDesktop';
import Shipping from './Shipping';
import MostRequested from './MostRequested';
import Features from './Features';
import AboutDefa from './AboutDefa';
import Contact from './Contact';
import BestBrands from './BestBrands';

const Home = ({ location }) => {
	useEffect(
		() => {
			if (/^\?section=(sobre-defa|consulta)$/.test(location.search)) {
				smoothScroll('#sobre-defa');
			}
		},
		[location]
	);

	return (
		<Page>
			<MediaMobile>{matches => (matches ? <BannerSlider /> : <BannerSliderDesktop />)}</MediaMobile>
			<MediaMobile render={() => <Shipping />} />
			<MostRequested />
			<Features />
			<Styled.AboutDefaAndContactContainer>
				<AboutDefa />
				<Contact />
			</Styled.AboutDefaAndContactContainer>
			<BestBrands />
			{/* <MediaMobile render={() => <BestBrands />} /> */}
		</Page>
	);
};

Home.propTypes = {
	location: PropTypes.shape({
		search: PropTypes.string.isRequired
	}).isRequired
};

export default withRouter(Home);
