import React from 'react';
import Icon from 'components/Icon';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import data from 'data';
import { WhatsappButtonStyled } from './styles';

const theme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#1BD741'
		}
	}
});

const WhatsappButton = () => (
	<MuiThemeProvider theme={theme}>
		<WhatsappButtonStyled
			target="_blank"
			href={data.whatsappHref}
			aria-label="Mandar mensaje por whatsapp"
		>
			<Icon size={34} name="whatsapp2" />
		</WhatsappButtonStyled>
	</MuiThemeProvider>
);

export default WhatsappButton;
